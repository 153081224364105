import React from "react";
import { Button } from "@material-ui/core";
import useSheet from "react-jss";
import { robotoStack } from "../../typography";

const IconButton = ({ classes, ...props }) => (
	<Button
		variant="contained"
		{...props}
		className={`${classes.root} ${props.className || ""}`}
	/>
);

const styles = {
	root: {
		...robotoStack,
		fontWeight: "500",
		boxShadow: "none",
		border: "1.5px solid",
		borderColor: props => props.color,
		backgroundColor: "transparent",
		height: "238.5px",
		width: "360px",
		textAlign: "center",
		margin: 0,
		"&, &:hover, &:focus, &:active": {
			color: props => props.color,
			textDecoration: "none",
			boxShadow: "none",
			border: "1px solid",
			borderColor: props => props.color,
			backgroundColor: "transparent",
			margin: 0
		},
		fontSize: "1.12rem"
	}
};

export default useSheet(styles)(IconButton);
