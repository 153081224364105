import React from "react";
import { Row, Col } from "react-bootstrap";
import { robotoStack } from "../../typography";

const Contact = () => {
	return (
		<Row>
			<div className="home-section__container clearfix">
				<Col md={12}>
					<table className="table" style={{ border: "none" }}>
						<tbody style={{ border: "none" }}>
							<tr
								style={{
									border: "none",
									borderBottom: "1px solid rgba(164,161,161,0.4)"
								}}
							>
								<th
									scope="row"
									style={{
										...robotoStack,
										border: "none",
										color: "#4D4D4D",
										fontSize: "1.12rem"
									}}
								>
									Contact
								</th>
								<td
									style={{
										...robotoStack,
										border: "none",
										color: "#A4A1A1",
										fontSize: "1.12rem"
									}}
								>
									Let us know if we can help. (Mon - Fri, 8 a.m. - 5 p.m. CST)
								</td>
							</tr>
							<div style={{ paddingTop: "20px" }} />
							<tr>
								<th
									scope="row"
									style={{
										...robotoStack,
										border: "none",
										color: "#4D4D4D",
										fontSize: "1.12rem"
									}}
								>
									Phone
								</th>
								<td
									style={{
										...robotoStack,
										border: "none",
										color: "#A4A1A1",
										fontSize: "1.12rem",

										'& a[href^="tel"]': {
											color: "inherit",
											textDecoration: "none"
										}
									}}
								>
									<a href={"tel:+18336924842"}>833-MyCivic</a>
								</td>
							</tr>
							<tr>
								<th
									scope="row"
									style={{
										...robotoStack,
										border: "none",
										color: "#4D4D4D",
										fontSize: "1.12rem"
									}}
								>
									Address
								</th>
								<td
									style={{
										...robotoStack,
										border: "none",
										color: "#A4A1A1",
										fontSize: "1.12rem"
									}}
								>
									CivicSource<sup>®</sup>
									<div style={{ marginBottom: "10px" }} />
									New Orleans, LA 70150-0001
								</td>
							</tr>
							<tr>
								<th
									scope="row"
									style={{
										...robotoStack,
										border: "none",
										color: "#4D4D4D",
										fontSize: "1.12rem"
									}}
								>
									Email
								</th>
								<td
									style={{
										...robotoStack,
										border: "none",
										fontSize: "1.12rem"
									}}
								>
									<a
										href="mailto:support@civicsource.com"
										style={{ color: "#7133FF" }}
									>
										CivicSource Support
									</a>
								</td>
							</tr>
							<tr>
								<th
									scope="row"
									style={{
										...robotoStack,
										border: "none",
										color: "#4D4D4D",
										fontSize: "1.12rem"
									}}
								>
									Sessions
								</th>
								<td
									style={{
										...robotoStack,
										border: "none",
										fontSize: "1.12rem"
									}}
								>
									<a
										href="https://www.civicsource.com/sessions"
										style={{ color: "#7133FF" }}
									>
										CivicSource Sessions
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</div>
		</Row>
	);
};

export default Contact;
