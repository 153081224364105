const COLOR_BLUE = "#0F70FF";
const COLOR_GREEN = "#08B681";

export const ICON_TYPES = {
	CERT: "Cert",
	ADJ: "Adj",
	GEN: "Gen"
};

const lienButton = {
	color: COLOR_GREEN,
	to: "/support/investment",
	text: "investment",
	type: ICON_TYPES.CERT
};

const deedButton = {
	color: COLOR_BLUE,
	to: "//civicsource.auctions.store/support",
	text: "property",
	type: ICON_TYPES.ADJ
};

const generalButton = {
	color: "#7133FF",
	to: "/support/general",
	text: "site",
	type: ICON_TYPES.GEN
};

export const fisherPriceButtons = [deedButton, lienButton, generalButton];
