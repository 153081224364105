import React from "react";
import { Grid, Row, Col, Panel } from "react-bootstrap";
import Link from "../../maybe-gatsby-link";
import useSheet from "react-jss";
import { bootstrap } from "toetag";

import Contact from "./contact";
import { fisherPriceButtons, ICON_TYPES } from "./fisher-price-buttons";
import IconButton from "./icon-button";
import { Helmet } from "react-helmet";
import { SiteIcon, InvestmentIcon, PropertyIcon } from "./icons";

const Support = ({ contacts, classes }) => {
	return (
		<Grid>
			<Helmet>
				<title>Support</title>
			</Helmet>
			<Row>
				<Col>
					<Panel style={{ border: "none" }}>
						<Row>
							<Grid>
								<div className={classes.iconContainer}>
									{fisherPriceButtons.map(({ color, to, text, type }) => {
										const FisherPriceIcon =
											type === ICON_TYPES.CERT
												? InvestmentIcon
												: type === ICON_TYPES.ADJ
												? PropertyIcon
												: type === ICON_TYPES.GEN
												? SiteIcon
												: null;

										return (
											<IconButton
												key={type}
												component={Link}
												to={to}
												color={color}
												margin={0}
												style={{
													paddingTop: "2.5em",
													paddingBottom: "2.5em",
													paddingRight: "5.5em",
													paddingLeft: "5.5em",
													marginTop: "1em",
													marginBottom: "1em",
													textTransform: "capitalize",
													borderRadius: 20,
													outline: "none"
												}}
											>
												<h4>
													{FisherPriceIcon ? (
														<FisherPriceIcon
															className={classes.icon}
															style={{ fontSize: 100 }}
														/>
													) : (
														<i
															className={`fa fa-fw fa-ban`}
															style={{ color: "#fff !important" }}
														/>
													)}
													<br />
													{text.split(" ").map(t => (
														<span key={t}>
															<span style={{ fontSize: "16px" }}>{t}</span>
															<br />
														</span>
													))}
												</h4>
											</IconButton>
										);
									})}
								</div>

								<br />
								<br />

								<Contact {...{ contacts }} />
							</Grid>
						</Row>
					</Panel>
				</Col>
			</Row>
		</Grid>
	);
};

const styles = {
	icon: {
		display: "block",
		background: {
			size: ["auto", 50],
			repeat: "no-repeat",
			position: ["50%", "50%"]
		},
		margin: 0,
		height: 100,
		width: "100%",
		[`@media only screen and (max-width: ${bootstrap.screenXsMax})`]: {
			display: "inline-block",
			verticalAlign: "bottom",
			width: "2em",
			height: "1em",
			background: {
				size: ["auto", "100%"],
				position: ["100%", "50%"],
				repeat: "no-repeat"
			}
		}
	},
	iconContainer: {
		display: "flex",
		justifyContent: "space-between",
		"& a": {
			margin: 0
		}
	}
};

export default useSheet(styles)(Support);
