import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	siteIcon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "80px",
		height: "80px"
	},
	investmentIcon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "80px",
		height: "80px"
	},
	propertyIcon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "80px",
		height: "80px"
	}
});

export const SiteIcon = styled(({ classes }) => (
	<svg
		className={classes.siteIcon}
		viewBox="0 0 80 80"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="Home-Page"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Support"
				transform="translate(-1175.000000, -203.000000)"
				stroke="#7133FF"
				strokeWidth="1.5"
			>
				<g id="site_icon_80px" transform="translate(1175.000000, 203.000000)">
					<g>
						<rect
							id="Rectangle"
							x="0.75"
							y="0.75"
							width="78.5"
							height="61.5"
							rx="3"
						/>
						<path
							d="M67,72.75 L13,72.75 C12.3786797,72.75 11.8161797,73.0018398 11.4090097,73.4090097 C11.0018398,73.8161797 10.75,74.3786797 10.75,75 L10.75,79.25 L69.25,79.25 L69.25,75 C69.25,74.3786797 68.9981602,73.8161797 68.5909903,73.4090097 C68.1838203,73.0018398 67.6213203,72.75 67,72.75 Z"
							id="Rectangle-Copy-4"
						/>
						<path
							d="M24.0291748,62.4597168 C24.8817952,67.1762695 27.0770671,70.5293783 30.6149902,72.519043"
							id="Path-15-Copy"
							transform="translate(27.322083, 67.489380) scale(-1, 1) translate(-27.322083, -67.489380) "
						/>
						<path
							d="M50,62.4597168 C50.8526204,67.1762695 53.0478923,70.5293783 56.5858154,72.519043"
							id="Path-15-Copy-2"
						/>
						<line x1="0" y1="50.4985352" x2="80" y2="50.4985352" id="Path-16" />
					</g>
				</g>
			</g>
		</g>
	</svg>
));

export const InvestmentIcon = styled(({ classes }) => (
	<svg
		className={classes.investmentIcon}
		viewBox="0 0 160 160"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="investment_icon_160px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="investment_icon_large-copy"
				transform="translate(1.000000, 1.000000)"
				stroke="#08B681"
				strokeWidth="2.5"
			>
				<g>
					<path
						d="M122,0 L155,0 C156.656854,-3.04359188e-16 158,1.34314575 158,3 L158,158 L158,158 L119,158 L119,3 C119,1.34314575 120.343146,3.04359188e-16 122,0 Z"
						id="Rectangle"
					/>
					<path
						d="M62,59 L96,59 C97.6568542,59 99,60.3431458 99,62 L99,158 L99,158 L59,158 L59,62 C59,60.3431458 60.3431458,59 62,59 Z"
						id="Rectangle-Copy-2"
					/>
					<path
						d="M3,107 L36,107 C37.6568542,107 39,108.343146 39,110 L39,158 L39,158 L0,158 L0,110 C-2.02906125e-16,108.343146 1.34314575,107 3,107 Z"
						id="Rectangle-Copy-3"
					/>
					<polyline
						id="Path-7-Copy"
						strokeLinecap="round"
						strokeLinejoin="round"
						points="0 74 85 0 59.5356115 0"
					/>
					<path d="" id="Path-13" />
					<line
						x1="85"
						y1="0"
						x2="85"
						y2="23"
						id="Path-14"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</g>
	</svg>
));

export const PropertyIcon = styled(({ classes }) => (
	<svg
		className={classes.propertyIcon}
		viewBox="0 0 90 88"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXink="http://www.w3.org/1999/xlink"
	>
		<g
			id="property_icon_90x88px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="property_icon_large-copy"
				transform="translate(2.000000, 4.000000)"
				stroke="#0F70FF"
				strokeWidth="1.5"
			>
				<line
					x1="0"
					y1="79.5"
					x2="86"
					y2="79.5"
					id="Path-6"
					strokeLinecap="round"
				/>
				<polyline
					id="Path-7"
					strokeLinecap="round"
					strokeLinejoin="round"
					points="-1.9095836e-13 39 43.228566 0 86 39"
				/>
				<line x1="79.5" y1="80" x2="79.5" y2="33.1547852" id="Path-12" />
				<line x1="6.5" y1="80" x2="6.5" y2="33.1547852" id="Path-12-Copy" />
				<path
					d="M13,28 L13,1.75078141 C13,0.783851536 13.6715729,0 14.5,0 L21.5,0 C22.3284271,0 23,0.783851536 23,1.75078141 L23,17.9959966"
					id="Path"
				/>
				<path
					d="M30,79 L30,47.6779661 C30,46.751251 31.036141,46 32.3142857,46 L54.6857143,46 C55.963859,46 57,46.751251 57,47.6779661 L57,79"
					id="Path"
				/>
			</g>
		</g>
	</svg>
));
