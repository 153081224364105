import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import Support from "../../content/support";

const SupportPage = ({ data }) => {
	const contacts = convertNodes(get(data, "allContentfulContactInfo"));

	return <Support {...{ contacts }} />;
};

function convertNodes(all) {
	const nodes = get(all, "edges");
	if (!nodes) return [];
	return nodes.map(n => n.node);
}

export default SupportPage;

export const pageQuery = graphql`
	query SupportQuery {
		allContentfulContactInfo {
			edges {
				node {
					type
					value
					id
				}
			}
		}
	}
`;
